<template>
  <div>
    <b-modal
      @show="showModal"
      @hidden="hideModal"
      id="my-modal"
      ref="my-modal"
      size="lg"
      class="rounded-0"
      centered
      title="Slider Güncelle"
    >
      <b-card-text>
        <b-row>
          <b-col cols="12" md="8">
            <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Başlık">
                <b-form-input
                  class="rounded-0"
                  ref="baslik"
                  v-model="form.baslik"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Dil">
              <v-select
                v-model="form.dil"
                :options="diller"
                :reduce="(diller) => diller.lang"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
                @input="handlerGetDilData($event)"
                :disabled="!form._id"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Açıklama">
              <b-form-textarea class="rounded-0" ref="baslik" v-model="form.aciklama" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4">
            <b-form-group label="Konum" description="Nerede gösterileceğini seç">
              <v-select
                v-model="form.konum"
                :options="konumlar"
                :reduce="(konum) => konum.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Yeni Sekme" description="Tıklandıgında yeni sekmede aç">
              <v-select
                v-model="form.sekme"
                :options="sekmeler"
                :reduce="(sekme) => sekme.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Statu" description="Gösterim durumu">
              <v-select
                v-model="form.statu"
                :options="statuler"
                :reduce="(statu) => statu.id"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <feather-icon class="cursor-pointer" icon="SaveIcon" size="16" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            Güncelle
          </b-button>
          <b-button squared variant="danger" class="float-right mr-1" @click="handlerRemove(form._id)">
            <feather-icon class="cursor-pointer" icon="Trash2Icon" size="16" />
            Sil
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import vSelect from 'vue-select';
import { diller } from '@core/mixins/ui/diller';
export default {
  components: {
    vSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: true,
      default: {},
    },
  },
  mixins: [diller],
  data() {
    return {
      show: false,
      konumlar: [
        { id: 'top', title: 'Üst Slider' },
        { id: 'bottom', title: 'Alt Slider' },
      ],
      sekmeler: [
        { id: 0, title: 'Hayır' },
        { id: 1, title: 'Evet' },
      ],
      statuler: [
        { id: true, title: 'Aktif' },
        { id: false, title: 'Pasif' },
      ],
      form: {
        _id: null,
        dil: 'tr',
        baslik: null,
        aciklama: null,
        url: '',
        konum: 'top',
        sekme: 0,
        statu: true,
      },
    };
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$nextTick(() => {
        this.$bvModal.hide('my-modal');
      });
      this.$emit('closeModal', true);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.show = true;
      this.$store
        .dispatch('slideGuncelle', this.form)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Güncelleme Başarılı',
              },
            });
            this.show = false;
          }
        })
        .catch((err) => {
          this.show = false;
          if (err.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },

    async handlerGetDilData(dil) {
      if (Object.keys(this.form).length > 0) {
        let item = this.form.icerik.find((x) => x.dil == dil);
        this.form.dil = dil;
        if (item != undefined) {
          this.form.baslik = item.baslik;
          this.form.aciklama = item.aciklama;
        } else {
          this.form.baslik = null;
          this.form.aciklama = null;
        }
      }
    },

    handlerRemove(id) {
      this.$swal({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          this.$store.dispatch('slideSil', id).then((res) => {
            if (res.data.success === true) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Silme`,
                  icon: 'Trash2Icon',
                  variant: 'danger',
                  text: `Silme başarılı.`,
                },
              });
              this.hideModal();
            }
          });
        }
      });
    },
  },
  watch: {
    openModal: {
      handler(val) {
        if (val == true) {
          this.showModal();
          this.form._id = this.updateData._id;
          this.form.icerik = this.updateData.icerik;
          this.form.dil = this.updateData.icerik[0].dil;
          this.form.baslik = this.updateData.icerik[0].baslik;
          this.form.aciklama = this.updateData.icerik[0].aciklama;
          this.form.url = this.updateData.url;
          this.form.konum = this.updateData.konum;
          this.form.sekme = this.updateData.sekme;
          this.form.statu = this.updateData.statu;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
