<template>
  <div>
    <b-card-actions
      ref="cardAction"
      title="Slider"
      icon="ImageIcon"
      :showLoading="show"
      :actionCollapse="false"
      :actionRefresh="true"
      :actionNewAdd="true"
      :collapsed="false"
      @refresh="refreshStop('cardAction')"
      @newAdd="handlerNewAdd($event)"
    >
      <template v-slot:body>
        <b-row v-if="slider.length == 0">
          <b-col>
            <b-alert variant="warning" class="text-center rounded-0" show>
              <h4 class="alert-heading">Opss !!</h4>
              <div class="alert-body">Gösterilecek Slider bulunamadı.</div>
            </b-alert>
          </b-col>
        </b-row>
        <draggable v-model="slider" handle=".draggable-task-handle" @end="handlerMenuChange" v-else>
          <transition-group type="transition" name="flip-list" class="row" tag="div">
            <b-col md="3" v-for="item in slider" :key="item._id">
              <slider-item :item="item" @refresh="handlerRefresh" class="draggable-task-handle" />
            </b-col>
          </transition-group>
        </draggable>
      </template>
    </b-card-actions>
    <slider-ekle :openModal="openModal" @closeModal="closeModal = $event" />
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import SliderItem from './component/sliderItem.vue';
import SliderEkle from './component/sliderEkle.vue';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
export default {
  components: {
    BCardActions,
    SliderItem,
    SliderEkle,
    draggable,
  },
  data: () => ({
    show: false,
    openModal: false,
    closeModal: false,
    slider: [],
  }),
  computed: {
    ...mapGetters(['getSlider']),
  },
  created() {
    this.handlerGetData();
  },
  methods: {
    refreshStop() {
      this.handlerGetData();
    },
    handlerNewAdd() {
      this.openModal = true;
    },
    handlerRefresh() {
      this.slider = [];
      this.slider = this.getSlider;
    },
    async handlerGetData() {
      this.show = true;
      await this.$store.dispatch('slideListele');
      this.handlerRefresh();
      this.show = false;
    },
    handlerMenuChange() {
      this.$store
        .dispatch('slideSiraGuncelle', this.slider)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: 'Sira Güncelleme Başarılı.',
              },
            });
          }
        })
        .catch((err) => {
          if (err.data.success == false) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Hata`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text:
                  Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              },
            });
          }
        });
    },
  },
  watch: {
    closeModal(val) {
      this.openModal = false;
      this.closeModal = false;
      this.handlerRefresh();
    },
  },
};
</script>

<style lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
